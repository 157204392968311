import React, {memo, useEffect} from "react";
import "./Header.scoped.scss";
import {CustomLink, Image, TertiaryButton} from "components/common";
import {useLoggedInStatus, useProfileData, useToggle} from "hooks";
import {Button, Hamburger, SecondaryButton} from "../../common";
import {Container, LenderNavigation} from "../index";
import {HamburgerColor} from "../../common/utils/Hamburger/Hamburger";
import {SecondaryButtonColor} from "../../common/buttons/decorators/SecondaryButton/SecondaryButton";
import ProfileMenu from "../../user/profile/ProfileMenu/ProfileMenu";
import {RouteDictionary} from "../../../utils";
import {LogoImage} from "../../common/images/types";
import {LogoType} from "../../common/images/types/LogoImage";
import NotificationLink from "../../common/buttons/decorators/NotificationLink";
import MobileNavigation from "../MobileNavigation";
import {TertiaryButtonColor} from "../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import {useWindowWidth} from "../../pages/detailed-project/hooks";
import cross from "assets/images/svg/profile-menu-cross.svg";


type Props = {
    isPublic?: boolean;
}

const Header = ({isPublic}: Props) => {
  const [active, toggle, setActive] = useToggle();
  const profileData = useProfileData();
  const isLoggedIn = useLoggedInStatus();
  const isMobile = useWindowWidth(992);
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [active]);
  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setActive(false);
    }
  };
  return (
    <div className={`header__wrapper ${active ? "header__wrapper__active" : ''}`} onClick={handleWrapperClick}>
      <header className="header">
        <Container>
          <nav className="header__navigation">
            <div className="header__navigation__left">
              {
                isLoggedIn && isMobile ? <><ProfileMenu clickable={false}/>
                  <div className="header__mobile__notifications">
                    <NotificationLink/>
                    {profileData.newNotificationCount > 0 &&
                    <span
                      className="header__mobile__notifications__notification-count">{profileData.newNotificationCount}</span>}
                  </div>
                </> : <div className="header__logo-container">
                  <CustomLink className="index-link" to={RouteDictionary.INDEX}>
                    <LogoImage className="header__logo" type={LogoType.GREEN}/>
                  </CustomLink>
                </div>
              }
              {isPublic && <div className="header__projects">
                <CustomLink to={RouteDictionary.PRIMARY_MARKET} className="header__projects-link">
                  <Hamburger rows={3} color={HamburgerColor.GREEN}/>
                  <div>
                    Проекты
                  </div>
                </CustomLink>
              </div> }
            </div>
            <div className="header__navigation__right">
              { !isLoggedIn ? <div className="header__auth-menu">
                Войти как
                <SecondaryButton color={SecondaryButtonColor.GREEN}>
                  <CustomLink to={RouteDictionary.ENTER} >
                    Инвестор
                  </CustomLink>
                </SecondaryButton>
                или
                <SecondaryButton color={SecondaryButtonColor.ORANGE}>
                  <Button onClick={() => console.log("123")}>
                    Заемщик
                  </Button>
                </SecondaryButton>
              </div> : <div className="header__user-menu">
                <div className="header__notifications">
                  <NotificationLink/>
                  {profileData.newNotificationCount > 0 &&
                  <span className="header__notifications__notification-count">{profileData.newNotificationCount}</span>}
                </div>
                <div className="header__profile">
                  <ProfileMenu />
                </div>
              </div> }
              <div className="header__mobile-open">
                {active ? <Image src={cross} onClick={toggle} className="header__mobile-open__cross"/> : <Hamburger onClick={toggle} rows={3}/>}
              </div>
            </div>
          </nav>
          { isLoggedIn && <>
            {active &&  <div className="mobile-navigation__money">
              <TertiaryButton expanded color={TertiaryButtonColor.GREEN}>
                <CustomLink  to={RouteDictionary.DEPOSIT}>
                  Пополнить
                </CustomLink>
              </TertiaryButton>
              <TertiaryButton expanded color={TertiaryButtonColor.GRAY}>
                <CustomLink to={RouteDictionary.WITHDRAW}>
                  Вывести
                </CustomLink>
              </TertiaryButton>
            </div>
            }
            <LenderNavigation/>
          </>}
          <MobileNavigation active={active} onNavigated={() => setActive(false)} />
        </Container>
      </header>
    </div>
  );
};

export default memo(Header);
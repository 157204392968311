import React, {FC, useState} from "react";
import {ConfirmSmsCodeForm, Modal} from "../../../index";
import {sleep} from "../../../../utils";
import './SignAgentsContractModal.scoped.scss';
import {useEffectOnUpdate, useErrorCallback,} from "../../../../hooks";
import {createProfileManager, createSignContractManager} from "../../../../di";
import {ModalProps} from "../Modal/Modal";
import {useDispatch} from "react-redux";
import {addAgentCode} from "../../../../store/profile";
import {InputErrors} from "../../inputs";

type Props = {
  closeSmsModal: () => void;
  documentUuid: string;
}
const SignAgentsContractModal: FC<ModalProps & Props> = ({active, onClose, closeSmsModal, documentUuid}) => {
  const closeContractModal = onClose;
  const [error, setError] = useState<string>();
  const [confirmationUuid, setConfirmationUuid] = useState("");
  const handleError = useErrorCallback();
  const dispatch = useDispatch();

  useEffectOnUpdate(() => {
    if (active) {
      const callback = async () => {
        const manager = await createSignContractManager();
        const result = await manager.requestCode();
        setConfirmationUuid(result.uuid);
      };
      (async () => {
        try {
          await callback();
        } catch (err: any) {
          if (err instanceof Error) {
            await handleError(err);
            setError(err.message);
          }
        }
      })();
    }
  }, [active]);

  const sendSms = async (smsCode: string) => {
    try {
      const manager = await createProfileManager();
      const agentCode = await manager.signContract();
      dispatch(addAgentCode(agentCode));
      const passwordManager = await createSignContractManager();
      await passwordManager.confirmCode(confirmationUuid, smsCode, documentUuid);
      closeContractModal();
      closeSmsModal();
    } catch (err) {
      if (err instanceof Error) {
        await handleError(err);
        setError(err.message);
      }
    }
  };

  const resendSms = async () => {
    //TODO: ADD RESENDING SMS
    try {
      await sleep(3000);
    } catch (error: any) {
      await handleError(error);
    }
  };

  const successCallback = () => {
  };

  return (
    <Modal active={active} onClose={closeSmsModal}>
      <Modal.Close/>
      {error && <InputErrors errors={[error]}/>}
      <div>
        <ConfirmSmsCodeForm
          sendCallback={sendSms}
          resendCallback={resendSms}
          successCallback={successCallback}
          prefix={`sign-contract-${confirmationUuid}`}/>
      </div>
    </Modal>
  );
};

export default SignAgentsContractModal;

